import axios from '@/utils/request'

// 系统管理-角色列表
export function getRoles(params = {}) {
  // params.loading = true
	return axios({
		url: `/manager/system/roles`,
		method: 'get',
		params
	})
}
// 系统管理-角色详情
export function getRolesDetail(roleId) {
	return axios({
		url: `/manager/system/roles/${roleId}`,
		method: 'get'
	})
}
// 系统管理-编辑角色
export function setRoles(data) {
	return axios({
		url: `/manager/system/roles/${data.roleId}`,
		method: 'post',
		data
	})
}
// 系统管理-编辑角色
export function delRoles(roleId) {
	return axios({
		url: `/manager/system/roles/${roleId}`,
		method: 'delete'
	})
}
// 系统管理-员工管理
export function getUsers(params = {}) {
  // params.loading = true
	return axios({
		url: `/manager/system/users`,
		method: 'get',
		params
	})
}
// 系统管理-编辑员工
export function setUsers(data) {
	return axios({
		url: `/manager/system/users/${data.userId}`,
		method: 'post',
		data
	})
}
// 系统管理-删除员工
export function delUsers(userId) {
	return axios({
		url: `/manager/system/users/${userId}`,
		method: 'delete'
	})
}
// 系统管理-激活或冻结账户
export function setUsersStatus(data) {
	return axios({
		url: `/manager/system/users/${data.userId}/status`,
		method: 'put',
		data
	})
}

// 系统管理-登录日志
export function getLogs(params) {
  // params.loading = true
	return axios({
		url: `/manager/system/logs`,
		method: 'get',
		params
	})
}
// 系统管理-查询系统配置
export function getConfig(params) {
	return axios({
		url: `/manager/system/config`,
		method: 'get',
		params
	})
}
// 系统配置-编辑系统配置
export function setConfig(data) {
	return axios({
		url: `/manager/system/config`,
		method: 'post',
		data
	})
}
//  随机密钥生成
export function getGeneratesecret() {
	return axios({
		url: `/manager/system/config/generatesecret`,
		method: 'get'
	})
}

//用户隐私二次认证
export function secondaryPut(data) {
  return axios({
    url: `/manager/user/setDisplayTime`,
    method: 'put',
		data
  })
}
export function getDisplayTime() {
	return axios({
		url: `/manager/user/getDisplayTime`,
		method: 'get'
	})
}
