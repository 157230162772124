<template>
  <div id="mian">
    <!-- 上面表单框 -->
    <div class="con_from">
      <div class="lineP">
        <div class="item">
          <i>用户名</i>
          <el-input
            v-model="query.keyword"
            maxlength="128"
            suffix-icon="el-icon-search"
            placeholder="用户名/姓名"
          ></el-input>
        </div>
        <div class="item">
          <i>状态</i>
          <el-select
            v-model="query.enabledStatus"
            placeholder="全部"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="正常" :value="true"></el-option>
            <el-option label="冻结" :value="false"></el-option>
          </el-select>
        </div>
        <div class="itembtn">
          <el-button class="searchBt" @click="getLists">查询</el-button>
          <el-button class="outputBt" @click="addShow()" v-auth="'SYSTEM:SYSTEM:EMPLOYEES/ADD'">新增员工</el-button>
        </div>
      </div>
    </div>
    <div class="tab1">
      <table class="tab">
        <tr class="tab_title">
          <td>
            <p>用户名</p>
          </td>
          <td>
            <p>姓名</p>
          </td>
          <td>
            <p>手机号</p>
          </td>
          <td>
            <p>角色分配</p>
          </td>
          <td>
            <p>状态</p>
          </td>
          <td>
            <p>操作</p>
          </td>
        </tr>
        <tr v-for="(v, i) in tabData" :key="i">
          <td>
            <p>{{ v.username }}</p>
          </td>
          <td>
            <p>{{ v.realname }}</p>
          </td>
          <td>
            <p>{{ v.phoneNumber }}</p>
          </td>
          <td>
            <p>{{ v.roleName }}</p>
          </td>
          <td>
            <p v-show="OFFON_EnabledStatus != 'SYSTEM:SYSTEM:EMPLOYEES/STATUS'"> {{ v.enabledStatus==true ? "已开启" : "已关闭" }}</p>
            <p v-auth="'SYSTEM:SYSTEM:EMPLOYEES/STATUS'">
              <el-switch
                v-model="v.enabledStatus"
                @change="setStatus(v)"
                active-color="#13ce66"
              >
              </el-switch>
            </p>
          </td>
          <td>
            <p>
              <i class="lianjie" v-auth="'SYSTEM:SYSTEM:EMPLOYEES/EDIT'" @click="editUser(v)"
                >编辑</i
              >
              <i class="lianjie" v-auth="'SYSTEM:SYSTEM:EMPLOYEES/DELETE'" @click="delUser(v)">删除</i>
            </p>
          </td>
        </tr>
      </table>
    </div>
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="query.pageNo"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalNum"
    >
    </el-pagination>
    <el-dialog
      :title="editShow === null ? '新增员工' : '编辑员工'"
      :visible.sync="addUserShow"
      width="490px"
    >
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title"><i>*</i>用户名</div>
          <div v-if="formData.userId == 0">
            <!-- {{userInfo.username}} -->
            <el-input
              v-model="formData.username"
              placeholder="输入角色名称"
              style="width: 120px; margin-right: 5px"
              tabindex="1"
            ></el-input
            >@<span class="username hidden1">youths</span>
          </div>
          <div style="line-height: 40px; margin-bottom: 8px" v-else>
            {{ formData.username }}
          </div>
          <div class="overAccount_ul_title"><i>*</i>手机号码</div>
          <el-input
            v-model="formData.phoneNumber"
            maxlength="11"
            placeholder="输入手机号码"
            tabindex="3"
          ></el-input>
          <div class="overAccount_ul_title"><i>*</i>角色分配</div>
          <el-select
            v-model="formData.roleId"
            placeholder="选择角色"
            tabindex="5"
          >
            <el-option
              v-for="(v, i) in roleDrop"
              :key="i"
              :label="v.roleName"
              :value="v.roleId"
            >
            </el-option>
          </el-select>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title"><i>*</i>姓名</div>
          <el-input
            v-model="formData.realname"
            placeholder="输入姓名"
            tabindex="2"
            :readonly='readonly'
            @focus='handlerIptClick'
            auto-complete="new-password"
          ></el-input>
          <div class="overAccount_ul_title"><i  v-if="editShow===null">*</i>登录密码</div>
          <div>
            <el-input
            v-model="formData.password"
            show-password
            :placeholder="formData.userId == 0 ? '输入登录密码' : '输入表示修改密码'"
            tabindex="4"
            :readonly='readonly'
            @focus='handlerIptClick'
            auto-complete="new-password"
          ></el-input>
          </div>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="addUserShow = false">取 消</el-button>
        <el-button class="addBt" @click="onSubmit" :disabled="buttonFlag">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getRoles, 
  getUsers,
  setUsers,
  setUsersStatus,
  delUsers,
} from "@/api/management/index.js";
import md5 from "js-md5";
import { mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      readonly:true,
      addUserShow: false,
      query: {
        pageNo: 1,
        pageSize: 10,
        keyword: "",
        enabledStatus: "",
      },
      totalNum: 0,
      tabData: [],
      roleDrop: [],
      formData: {
        userId: 0,
        username: "",
        realname: "",
        phoneNumber: "",
        roleId: "",
        password: "",
      },
      userInfo: "",
      editShow: null,
      OFFON_EnabledStatus:'SYSTEM:SYSTEM:EMPLOYEES/STATUS',
      buttonFlag:false,
    };
  },
  computed: {
    ...mapState({
      staffQueryDetail: "system_staffQuery",
    }),
  },
  created() {
    if (this.staffQueryDetail) {
      this.query = this.staffQueryDetail.query;
    }
    this.getLists(false);
    this.getDrop();
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.$enter(this.$route.path, this.getLists);
    this.listJurisdiction();
  },
  beforeDestroy() {
    let data = {
      query: this.query,
    };
    this.setStaffQuery(data);
  },
  methods: {
    ...mapMutations({
      setStaffQuery: "system_setStaffQuery",
    }),
    // 获取列表
    getLists(reset = true) {
      if (reset) {
        (this.query.pageNo = 1),
          (this.query.keyword = this.query.keyword.trim());
      }
      getUsers(this.query).then((res) => {
        if (res) {
          this.tabData = res.resultData.users;
          if (this.tabData.length == 0) {
            this.$message({
              message: "暂无数据",
              duration: 1500,
            });
          }
          this.totalNum = res.resultData.totalNum;
        }
      });
    },
    // 获取下拉
    getDrop() {
      getRoles().then((res) => {
        if (res) {
          this.roleDrop = res.resultData;
        }
      });
    },
    addShow() {
      this.formData = {
        userId: 0,
        username: "",
        realname: "",
        phoneNumber: "",
        roleId: "",
        password: "",
      };
      this.formData.realname = null;
      this.formData.password = null;
      
      this.editShow = null;
      this.addUserShow = true;
    },
    // 提交
    onSubmit() {
      let data = JSON.parse(JSON.stringify(this.formData));
      let passwordTest = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/
      if (!data.username) {
        this.$message.error("用户名必填");
        return;
      }
      if(this.editShow == null){
        if (!/^[1][2,3,4,5,6,7,8,9][0-9]{9}$/.test(data.phoneNumber)) {
          this.$message.error("请正确填写手机号码");
          return;
        }
      }
      if (!data.realname) {
        this.$message.error("姓名必填");
        return;
      }
      if (!data.roleId) {
        this.$message.error("角色必选");
        return;
      }
      if (data.userId == 0) {
        if (!data.password) {
          return this.$message.error("密码必填");
        } else if (
          !passwordTest.test(
            data.password
          )
        ) {
          return this.$message.error(
            "密码必须包含：大小写字母, 数字, 特殊符号, 长度至少8位以上"
          );
        }
        data.password = md5(data.password);
      } else {
        if (data.password) {
          // 正则验证密码复杂度
          if (
            !passwordTest.test(data.password)
          ) {
            return this.$message.error(
              "密码必须包含：大小写字母, 数字, 特殊符号, 长度至少8位以上"
            );
          }
          data.password = md5(data.password);
        }
      }
      this.buttonFlag = true;
      setUsers(data).then((res) => {
        if (res.resultStatus) {
          this.$message.success("保存成功");
          this.getLists();
          this.addUserShow = false;
        } 
        setTimeout(() => {
          this.buttonFlag = false;
        }, 2000); 
      });
    },
    // 修改状态
    setStatus(value) {
      let data = {
        userId: value.userId,
        enabledStatus: value.enabledStatus,
      };
      setUsersStatus(data).then((res) => {
        if (!res) {
          value.enabledStatus = value.enabledStatus ? false : true;
        }
      });
    },
    // 修改
    editUser(value) {
      this.formData = JSON.parse(JSON.stringify(value));
      this.editShow = true;
      this.addUserShow = true;
    },
    //删除员工
    delUser(value) {
      this.$confirm("此操作将删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delUsers(value.userId).then((res) => {
            if (res) {
              this.getLists();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handlerIptClick(){
      this.readonly = false;
    },
    // 页面大小改变
    handleSizeChange(size) {
      this.query.pageSize = size;
      this.getLists();
    },
    handleCurrentChange(page) {
      this.query.pageNo = page;
      this.getLists(false);
    },
    //判断列表按钮是否有权限
    listJurisdiction(){
        const _resources = JSON.parse(sessionStorage.getItem('userInfo')).resources;
        if(_resources=='ALL'){
            this.OFFON_EnabledStatus = 'SYSTEM:SYSTEM:EMPLOYEES/STATUS';
        }else{
          if(_resources.split(';').indexOf('SYSTEM:SYSTEM:EMPLOYEES/STATUS') !== -1 ){
            this.OFFON_EnabledStatus = 'SYSTEM:SYSTEM:EMPLOYEES/STATUS';
          }else{
            this.OFFON_EnabledStatus = "";
          }
        }

    },
  },
};
</script>
<style scoped>
.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: #333333;
  font-size: 12px;
  padding: 0;
}

.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}

.overAccount_ul_title i {
  color: #48b8b6;
  padding-right: 3px;
}
.username {
  display: inline-block;
  vertical-align: middle;
  width: 60px;
}
</style>
